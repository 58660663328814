import Layout3 from '@/components/layouts/Layout3.vue';
import SiteFooter from '@/components/SiteFooter.vue';
import SiteHeader from '@/components/SiteHeader.vue';
import { useWidgetStore } from '@/stores/widget';
export default defineComponent({
  components: {
    SiteFooter: SiteFooter,
    SiteHeader: SiteHeader,
    Layout3: Layout3
  },
  setup: function setup() {
    var _useWidgetStore = useWidgetStore(),
      resetWidgets = _useWidgetStore.resetWidgets;
    onBeforeMount(resetWidgets);
  },
  head: function head() {
    return {
      title: 'Invitation Expired'
    };
  }
});